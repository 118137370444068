import React, { useEffect, useRef, useState } from 'react';
import { atom, useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import Markdown from 'react-markdown';
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { collection, query, where, onSnapshot } from "firebase/firestore";
import { useAuth0 } from '@auth0/auth0-react';

import { useAutoScroll } from '../../helpers/autoScroll';
import { TimeAgo } from '../../components/TimeAgo';
import { DB } from '../../App';
import { useNotification } from '../../contexts/notificationContext';
import { useData } from '../../contexts/dataContext';
import ChatInput from './components/ChatInput';
import Text from '../../components/Text';
import CustomButton from '../../components/CustomButton';

import './ChatView.css';

import profileIcon from '../../../assets/images/icons/profile.svg';

const emmaAvatar = new URL('/public/emma-avatar.png', import.meta.url).toString();


const inputAtom = atom('');
const isAtBottomAtom = atom<boolean>(false);

const AwaitingResponseIndicator: React.FC = () => {
    return (
        <div className="dot-flashing">
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
};


const ChatView: React.FC = () => {
    const translation = useTranslation();
    const { t } = translation;
    const { getAccessTokenSilently } = useAuth0();

    const [searchParams, setSearchParams] = useSearchParams();

    const [hasRendered, setHasRendered] = useState(false);
    const [isWelcome, setIsWelcome] = useState(false);
    const [autoSendMessage, setAutoSendMessage] = useState('');
    const [chatMessages, setChatMessages] = useState<Message[]>([]);

    const [input, setInput] = useAtom(inputAtom);
    const [isAtBottom, setIsAtBottom] = useAtom(isAtBottomAtom);

    const { messages, pendingMessages, setPendingMessages, awaitAI, setAwaitAI, glance, addNotification } = useData();

    const mergeMessages = (messages: Message[], pendingMessages: Message[]): Message[] => {
        const allMessages = [...messages, ...pendingMessages];
        const uniqueMessagesMap = new Map();

        allMessages.forEach((message) => {
            uniqueMessagesMap.set(message.id, message);
        });

        const mergedMessages = Array.from(uniqueMessagesMap.values());
        // if (awaitAI) {
        //     mergedMessages.push({
        //         id: 'awaiting-response',
        //         ts: new Date(),
        //         text: <AwaitingResponseIndicator />,
        //         sender: 'assistant'
        //     });
        // }

        return mergedMessages;
    };

    useEffect(() => {
        try {
            const signup = searchParams.get('signup');
            console.log('signup', signup);
            setIsWelcome(signup === 'true');
        } catch (error) {
            console.log('no searchParams');
            setIsWelcome(false);
        }
        try {
            let paramMessage = searchParams.get('message');
            const decodedMessage = paramMessage ? decodeURIComponent(paramMessage) : '';
            setAutoSendMessage(decodedMessage);
        } catch (error) {
            console.log('no searchParams');
            setAutoSendMessage('');
        }
        setHasRendered(true);
    }, [searchParams]);

    useEffect(() => {
        const mergedMessages = mergeMessages(messages, pendingMessages);
        setChatMessages(mergedMessages);
    }, [messages, pendingMessages, awaitAI]);

    useEffect(() => {
        if (autoSendMessage !== '') {
            handleSendMessage(autoSendMessage);
            searchParams.delete('message');
            setSearchParams(searchParams);
        }
    }, [autoSendMessage]);

    const handleSendMessage = async (text: string) => {
        if (!text) {
            return;
        }
        setInput('');

        const response = await fetch('https://api.dazzle.zebrain.se/message', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + await getAccessTokenSilently(),
            },
            body: JSON.stringify({
                message: text,
            }),
        });

        setAwaitAI(true);
        // get id from the response json data
        const responseJson = await response.json();
        const id = responseJson.id;
        setPendingMessages([
            ...pendingMessages,
            {
                id: id,
                ts: new Date(),
                text,
                sender: 'user',
            },
        ]);

    };

    const chatContainerRef = useRef(null);
    useAutoScroll(chatMessages, chatContainerRef, setIsAtBottom);

    const ClaspIndicator = () => {
        return (
            <div className="relative flex justify-center items-center h-0">
                {/* <div className="flex-grow"></div>
                <div className="h-1 bg-neutral-700 rounded w-10"></div>
                <div className="flex-grow"></div>
                <div className="flex-grow"></div>
                <div className="h-1 bg-neutral-700 rounded w-10"></div>
                <div className="flex-grow"></div> */}
            </div>
        );
    };

    if (!glance || !glance.user_client_info || !glance.user_client_info.user || glance.user_client_info.clients.length === 0) {
        return null
    }

    if (isWelcome) {
        return (
            <div className="flex justify-center h-full w-full bg-neutral-900 mt-[125px]">
                <div className="flex flex-col justify-start align-middle items-center gap-10 h-full w-full desktop:w-151 px-4">
                    <div>
                        <img
                            src={emmaAvatar}
                            alt="avatar"
                            className="w-[150px] h-[150px] rounded-full"
                        />
                    </div>
                    <p className="text-base font-medium dark:text-white text-center desktop:px-6">
                        {t('chat-welcome')}
                    </p>
                    <CustomButton
                        buttonType="blueBase"
                        extraStyle="mt-4 w-full"
                        onClick={() => {
                            setIsWelcome(false)
                            searchParams.delete('signup');
                            setSearchParams(searchParams);
                        }}>
                        {t('continue')}
                    </CustomButton>
                </div>
            </div>
        )
    }

    return (
        <>
                <div
                    className="h-[calc(100vh-288px)] desktop:h-[calc(100vh-200px)] overflow-y-auto mb-16 pr-4"
                    ref={chatContainerRef}
                >
                    {chatMessages && chatMessages.length !== 0 && chatMessages.filter((message) => (message.sender !== 'system' && message.sender !== 'function')).map((message, index) => (
                        <div key={message.id} className={`${index < chatMessages.length - 1 ? 'border-b border-neutral-700' : ''}`}>
                            <div className="flex flex-row">
                                <div className="p-2 py-6 flex items-start justify-center">
                                    <div className="flex w-10 h-10 flex-shrink-0 items-start">
                                        <img
                                            src={(message.sender === 'assistant') ? emmaAvatar : profileIcon}
                                            alt="avatar"
                                            className="w-8 h-8 rounded-full fill-white"
                                        />
                                    </div>
                                    <div>
                                        <div className='pb-1'>
                                            <span className='font-medium'>{message.sender === 'assistant' ? 'AI Emma' :  glance.user_client_info.clients[0].client_details.name}</span> <span className='font-medium text-neutral-400'><TimeAgo date={message.ts} /></span>
                                        </div>
                                        {message.id !== 'awaiting-response' ?
                                            <div className="prose dark:prose-invert prose-slate"><Markdown>{message.text}</Markdown></div> :
                                            message.text
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    {isAtBottom && <ClaspIndicator />}
                </div>
                    <div className="fixed bottom-0 bg-neutral-900 w-full desktop:w-151">
                        <ChatInput
                            pendingMessages={pendingMessages}
                            setPendingMessages={setPendingMessages}
                            setAwaitAI={setAwaitAI}
                            handleSendMessage={handleSendMessage}
                        />
                    </div>
            </>
    );
};

export default ChatView;

type Message = {
    id: string;
    ts: Date;
    text: string;
    sender: 'user' | 'assistant' | 'system' | 'function';
};
