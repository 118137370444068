import { useEffect, useLayoutEffect, useRef } from "react";

const useAutoScroll = (messages, containerRef, setIsAtBottom?) => {
    const atBottomRef = useRef(true);

    useEffect(() => {
        console.log('useAutoScroll');
        console.log('containerRef.current', containerRef.current);
        if (!containerRef.current) return;

        const handleScroll = () => {
            const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
            const nearBottom = scrollHeight - scrollTop - clientHeight < 2;
            atBottomRef.current = nearBottom;
            if (setIsAtBottom) {
                setIsAtBottom(nearBottom);
            }
        };

        const currentContainer = containerRef.current;
        currentContainer.addEventListener('scroll', handleScroll);

        return () => currentContainer.removeEventListener('scroll', handleScroll);
    }, [containerRef.current]);

    useEffect(() => {
        if (atBottomRef.current && containerRef.current) {
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
    }, [messages]);
};

export { useAutoScroll }