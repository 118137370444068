import React from 'react';
import Markdown from 'react-markdown';

interface Props {
    description: string;
    title: string;
    onClick?: () => void;
    icon?: string;
    deadline?: string;
}

const SimpleCard = (props: Props) => {
    const { description, title, onClick, icon, deadline } = props;
    return (
        <div
            className="flex flex-col gap-2 rounded-lg p-4 dark:bg-neutral-800 dark:hover:border-primary-500 hover:border"
            onClick={onClick}
        >
            <div className="flex justify-between items-center">
                <h3 className="text-base font-medium dark:text-white">
                    {title}
                </h3>
                {icon && (
                    <div className="flex items-center gap-2">
                        <img src={icon} alt="checkmark" />
                    </div>
                )}
            </div>
            <div className="text-base font-normal dark:text-neutral-300">
                <Markdown>{description}</Markdown>
            </div>
            {deadline && (
                <p className="text-base font-medium dark:text-neutral-300">
                    {deadline}
                </p>
            )}
        </div>
    );
}

export default SimpleCard;
