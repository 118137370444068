import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';

import CustomButton from '../../components/CustomButton';
import Modal from './components/Modal';
import { use } from 'i18next';


export default function SupportPage() {
  const translation = useTranslation();
  const { t } = translation;
  const { getAccessTokenSilently } = useAuth0();

  const [description, setDescription] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');


  const openModal = () => {
    const modal = document.getElementById('hs-basic-modal');
    if (modal) {
      modal.classList.remove('hidden');
      modal.classList.add('open');
      modal.classList.add('opened');
    }
  }

  const handleSendSupportTicket = async () => {
    if (!description) {
      setErrorMessage(t('support-error-message'));
      return;
    }

    setIsLoading(true);
    setErrorMessage('');
    setSuccessMessage('');

    try {
      const token = await getAccessTokenSilently();
      const response = await fetch('https://api.dazzle.zebrain.se/support_ticket', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          description,
        }),
      });

      if (response.ok) {
        setSuccessMessage(t('support-success-message'));
        setDescription('');

        openModal();
      } else {
        setErrorMessage(t('support-error-message-server'));
      }
    } catch (error) {
      setErrorMessage(t('support-error-message-server'));
    }

    setIsLoading(false);
  };

  return (
    <>
      <h1 className="text-2xl font-medium dark:text-white  my-4 desktop:my-6">
        {t("support-nav-title")}
      </h1>
      <div className="gap-2 flex flex-col overflow-y-auto">

        <div className="bg-neutral-900 rounded-md w-full ">

            <textarea
              id="description"
              placeholder={t('support-description-placeholder')}
              className="w-full h-40 p-3 mb-4 bg-neutral-900 rounded-lg focus:ring-2 ring-primary-500 ring-inset border dark:border-neutral-600 focus:outline-none"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          <CustomButton
            onClick={handleSendSupportTicket}
            extraStyle="w-full"
            extraProps={{ "disabled": isLoading }}
          >
            {isLoading ? t('support-sending') : t('send')}
          </CustomButton>
          <Modal title={t('support-modal-success-title')} description={t('support-modal-success-description')} />
          {errorMessage && <p className="mt-4 text-red-500">{errorMessage}</p>}
          <p className="mt-4 dark:text-white">{t('support-email-info')}</p>
          <p className="dark:text-neutral-400">support@zebrain.se</p>
        </div>
      </div>
    </>
  );
}