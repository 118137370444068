import React from 'react';
import { useMatches, useParams } from "react-router-dom";
import chevronRightIcon from '../../../assets/images/icons/chevronRight.svg';

const Breadcrumbs = () => {
    const { id } = useParams();
    const matches = useMatches();
    const crumbs = matches
        .filter((match) => Boolean(match.handle?.crumb))
        .map((match) => match.handle.crumb({ id }));

    if(crumbs.length < 2) {
        return null;
    }

    return (
        <nav aria-label="breadcrumb" className="w-full my-4 desktop:my-6 justify-center">
            <div className="flex  flex-col justify-start align-middle w-full desktop:w-151">
                <ol className="flex flex-wrap items-center text-neutral-400 text-xs">
                    {crumbs.map((crumb, index) => (
                        <li key={index} className="flex items-center">
                            <p className="hover:text-primary-500">{crumb}</p>
                            {index < crumbs.length - 1 && (
                                <img
                                    src={chevronRightIcon}
                                    alt="Chevron Right"
                                    className="w-4 h-4 mx-1.5"
                                />
                            )}
                        </li>
                    ))}
                </ol>
            </div>
        </nav>
    );
};

export default Breadcrumbs;
