import { RouterProvider, createBrowserRouter, useNavigate, Link } from "react-router-dom";
import AuthPage from "./pages/AuthPage";
import ChatView from "./pages/ChatView";
import Home from "./pages/Home";
import ProtectedPage from "./pages/ProtectedPage";
import TasksPage from "./pages/TasksPage";
import DevelopmentAreaPage from "./pages/DevelopmentAreaPage";
import FocusAreaPage from "./pages/FocusAreaPage";
import { App } from "./App";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import AcceptInvite from "./pages/AcceptInvitePage";
import SupportPage from "./pages/SupportPage";
import AboutPage from "./pages/AboutPage";
import CalendarPage from "./pages/CalendarPage";
import { useTranslation } from 'react-i18next';

const AppRouter = ({ outerRoute }) => {
  const { user, isAuthenticated, isLoading, loginWithRedirect, getAccessTokenSilently, logout: auth0logout } = useAuth0();
  const isAuth = isAuthenticated && !isLoading;

  const translation = useTranslation();
  const { t } = translation;

  let routes = [
    {
      path: '/',
      element: (
        <ChatView />
      )
    },
    {
      path: '/tasks',
      element: (
        <TasksPage />
      )
    },
    {
      path: '/accept_invite/:code',
      element: (
        <AcceptInvite />
      )
    },
    {
      path: '/development-plan',
      element: (
        <DevelopmentAreaPage />
      ),
      handle: {
        crumb: () => <Link to="/development-plan">{t("development-plan-nav-title")}</Link>,
      },
      children: [
        {
          path: '/development-plan/focus-area/:id',
          element: (
            <FocusAreaPage />
          ),
          handle: {
            crumb: (data) => (
              <Link to={`/development-plan/focus-area/${data.id}`}>
                {t("focus-area-nav-title")}
              </Link>
            ),
          },
        },
      ]
    },
    {
      path: '/about',
      element: (
        <AboutPage />
      )
    },
    {
      path: '/support',
      element: (
        <SupportPage />
      )
    },

    {
      path: '/calendar',
      element: (
        <CalendarPage />
      )
    },

    // { path: '/auth', element: <AuthPage /> },
  ];


  const router = createBrowserRouter([
    {
      path: "/",
      element: <App />,
      children: routes
    }
  ]);

  useEffect(() => {
    if (outerRoute) {
      router.navigate(outerRoute);
    }
  }
    , [outerRoute]);

  useEffect(() => {
    if (!isLoading) {
      if (!isAuthenticated) {
        const currentPath = window.location.pathname;
        const isAcceptInvite = currentPath.startsWith('/accept_invite/');

        const loginOptions = {
          appState: {
            returnTo: currentPath
          }
        };

        if (isAcceptInvite) {
          (loginOptions as any).authorizationParams = { screen_hint: "signup" };
        }

        loginWithRedirect(loginOptions);
      }
    }
  }, [isLoading, isAuthenticated]);

  return <RouterProvider router={router} />;
};

export default AppRouter;
