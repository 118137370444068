import React from 'react';

import SimpleCard from './components/SimpleCard';
import FocusCard from './components/FocusCard'

interface Props {
    type?: 'simple' | 'focusCard';
    title: string;
    description: string;
    deadline?: string;
    onClick?: () => void;
    leftIcon?: string;
    icon?: string;
}

const cardTypes = {
    simple: SimpleCard,
    focusCard: FocusCard,
};

const Card = (props: Props) => {
    const { type = 'simple', ...restProps } = props;
    
    const CardComponent = cardTypes[type];

    if (!CardComponent) {
        console.error(`Invalid card type: ${type}`);
        return null;
    }

    return <CardComponent {...restProps} />;
}

export default Card;

