import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
    resources: {
        en: {
            translation: {
                'deadline': 'Deadline',
                "continue": "Continue",
                "time-ago-now": "now",
                "send": "Send",
                "chat-placeholder": "Type your message here...",
                "chat-send": "Send",
                "chat-start": "Get started",
                "menu-chat": "Chat with Emma",
                "menu-my-tasks": "My tasks",
                "menu-development-plan": "My development plan",
                "menu-my-account": "My account",
                "menu-support": "Support",
                "menu-about-company": "About Zebrain",
                "task": "Task",
                "description": "Description",
                "logout": "Log out",
                "tasks-page-title": "Select task to work on",
                "auto-task-chat-message": "I want to work on the task",
                "development-area-page-title": "Select focus area",
                "focus-area-quick-select": "Quick select",
                "focus-area-start-chat": "I want to start coaching within the focus area",
                "focus-area-start-chat-button": "Start coaching session",
                "focus-area-learn-more-button": "I want to learn more about the topic",
                "chat-nav-title": "Chat",
                "tasks-nav-title": "My tasks",
                "ongoing": "Ongoing",
                "completed": "Completed",
                "completed-tasks": "Completed tasks",
                "development-plan-nav-title": "My development plan",
                "focus-area-nav-title": "Focus Area",
                "no-tasks": "You have not created any tasks.",
                "no-development-areas": "You have not created any focus areas.",
                "chat-welcome": "Hi, nice to meet you here!\nI'm your AI coach Emma, let's start with a short introduction.",
                "support-nav-title": "Support",
                "support-page-title": "What can we help you with?",
                "support-description-placeholder": "Describe your issue here...",
                "support-email-info": "You can also email us at",
                "support-modal-success-title": "Thank you for reaching out to us! ",
                "support-modal-success-description": "We will get back to you as soon as possible.",
                "support-error-message": "An error occurred, please try again later.",
                "about-nav-title": "About Zebrain",

                
            },
        },
        sv: {
            translation: {
                'deadline': 'Slutdatum',
                "continue": "Fortsätt",
                "send": "Skicka",
                "time-ago-now": "nu",
                "chat-placeholder": "Skriv ditt meddelande här...",
                "chat-send": "Skicka",
                "chat-start": "Kom igång",
                "menu-chat": "Chatta med Emma",
                "menu-my-tasks": "Mina uppgifter",
                "menu-development-plan": "Min utvecklingsplan",
                "menu-my-account": "Mitt konto",
                "menu-support": "Support",
                "menu-about-company": "Om Zebrain",
                "task": "Uppgift",
                "description": "Beskrivning",
                "logout": "Logga ut",
                "tasks-page-title": "Välj uppgift att arbeta med",
                "auto-task-chat-message": "Jag vill arbeta med uppgiften",
                "development-area-page-title": "Välj fokusområde",
                "focus-area-quick-select": "Snabbval",
                "focus-area-start-chat": "Jag vill starta coaching inom fokusområdet",
                "focus-area-start-chat-button": "Starta coachsession",
                "focus-area-learn-more-button": "Jag vill lära mig mer om ämnet",
                "chat-nav-title": "Chatt",
                "tasks-nav-title": "Mina uppgifter",
                "ongoing": "Pågående",
                "completed": "Färdiga",
                "completed-tasks": "Färdiga uppgifter",
                "development-plan-nav-title": "Min utvecklingsplan",
                "focus-area-nav-title": "Fokusområde",
                "no-tasks": "Du har inte skapat några uppgifter.",
                "no-development-areas": "Du har inte skapat några fokusområden.",
                "chat-welcome": "Hej, vad kul att träffa dig här!\nJag är din AI coach Emma, låt oss börja med en kort introduktion.",
                "support-nav-title": "Support",
                "support-page-title": "Vad kan vi hjälpa dig med?",
                "support-description-placeholder": "Beskriv ditt problem här...",
                "support-email-info": "Du kan också mejla oss på",
                "support-modal-success-title": "Tack för att du kontaktar oss! ",
                "support-modal-success-description": "Vi återkommer så snart som möjligt.",
                "support-error-message": "Ett fel uppstod, försök igen senare.",
                "about-nav-title": "Om Zebrain",
                
            },
        },
    },
    fallbackLng: "en",
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;