


import React from 'react';

interface Props {
    extraStyle?: string;
    extraProps?: {};
    buttonType?: string;
    onClick?: () => void;
    children: React.ReactNode;
}

const colorClasses = {
    blue: "bg-primary-500 hover:bg-primary-400 active:bg-primary-400 disabled:opacity-40 dark:text-neutral-900",
    'outline-white': "bg-transparent text-white border-white hover:bg-white hover:text-neutral-900 disabled:opacity-40"
}

const sizeClasses = {
    sm: "py-2 px-3",
    md: "py-3 px-4 text-button-md font-medium",
}

const buttonTypeClasses = {
    blueBase: `${colorClasses["blue"]} ${sizeClasses["md"]}`,
    outlineWhiteBase: `${colorClasses["outline-white"]} ${sizeClasses["md"]}`,
}

const CustomButton = (props : Props) => {
    const { extraStyle, buttonType = "blueBase", onClick, children } = props;
    const classes = `${buttonTypeClasses[buttonType]} inline-flex flex-col items-center gap-x-2 rounded-full border border-transparent disabled:pointer-events-none ${extraStyle}`
    return (
        <button
            type="button"
            className={classes}
            onClick={props.onClick}
        >
            {children}
        </button>
    );
}

export default CustomButton;
