import React from 'react';



interface Props {
    description: string;
    title: string;
    onClick?: () => void;
    leftIcon: string;
    icon?: string;
}

const FocusCard = (props: Props) => {
    const { description, title, onClick, leftIcon, icon } = props;
    return (
        <div
            className="flex flex-col rounded-lg w-full dark:bg-neutral-800 border-transparent dark:hover:border-opacity-1 dark:hover:border-primary-500 border cursor-pointer"
            onClick={onClick}
        >
            <div className="px-4 py-3 dark:bg-neutral-700 rounded-t-lg flex items-center">
                {leftIcon && <img src={leftIcon} alt="icon" className="w-6 h-6 mr-1.5" />}
                <h3 className="text-base font-medium dark:text-white">
                    {title}
                </h3>

                {icon && (
                    <div className="flex flex-col flex-grow items-end gap-2">
                        <img src={icon} alt="checkmark" />
                    </div>
                )}
            </div>
            <div className="p-4">
                <p className="text-base font-normal dark:text-neutral-300 line-clamp-3">
                    {description}
                </p>
            </div>
        </div>
    );
}

export default FocusCard;
